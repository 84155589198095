<template>
    <div class="flex h-full flex-col">
        <div
            class="flex items-center justify-between border-b border-gray-200 px-6 py-4"
        >
            <h5 class="text-lg font-medium leading-9 text-gray-900">
                {{ getReceiverFullName(room.participants, centralUser) }}
                {{ $t('accountantInbox.detail') }}
            </h5>
        </div>

        <accountant-email-chat-window-items :room="room" />

        <div class="border-t px-6 py-4">
            <form class="flex gap-3" @submit.prevent="sendMessage">
                <form-text-input
                    v-model="message"
                    :error-message="errorMessage"
                    hide-message
                    class="grow"
                    :placeholder="$t('chat.chatWindow.messageInputPlaceholder')"
                    autofocus
                />
                <base-button
                    type="submit"
                    class="p-3"
                    icon="line-icons:communication:send-01"
                    :has-padding="false"
                    :disabled="disabledSend"
                    :loading="loadingSend"
                />
            </form>
        </div>
    </div>
</template>

<script setup>
import { getReceiverFullName } from '@tenant/utils/helper'
import { useEmailMessage } from '@tenant/composables/use-chat'

const emitter = useEmitter()
const { centralUser } = useAuth()

const props = defineProps({
    room: {
        type: Object,
        required: true,
    },
})

const { message, errorMessage, disabledSend, loadingSend, onSendEmail } =
    useEmailMessage(props.room)

const sendMessage = () => {
    onSendEmail().then(({ message, chat_room_email }) => {
        emitter.emit('sent-message', {
            room_id: props.room.id,
            message,
            chat_room_email,
        })
    })
}
</script>
